import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/SEO";


const IndexPage = React.memo(({data}) => {
    return (
        <Layout>
            <SEO title="Home"/>
            <section className="about">
                <p>Coming Soon</p>
            </section>
        </Layout>
    );
});

export default IndexPage;
